<template>
  <div class="page">
    <Navbar title="工作预约" type="SMP" />
    <div class="records">
      <van-tabs :active="tabActive" :color="COLOR_M" border="true">
        <van-tab title="进行中" name="order">
          <van-row
            class="record bdc-b1s-gray"
            v-for="consult in consultList"
            :key="consult.code"
          >
            <van-col span="18" class="content">
              <div class="title text-short">{{ consult.taskName }}</div>
              <div class="code">
                业务类型：<van-tag
                  :color="COLOR_S1"
                  v-if="consult.businessType === 'RCT'"
                  >岗位咨询</van-tag
                ><van-tag
                  :color="COLOR_S1"
                  v-if="consult.businessType === 'TRA'"
                  >培训咨询</van-tag
                ><van-tag
                  :color="COLOR_S1"
                  v-if="consult.businessType === 'ERO'"
                  >上学咨询</van-tag
                ><van-tag
                  :color="COLOR_S1"
                  v-if="consult.businessType === 'IFM'"
                  >公招咨询</van-tag
                >
              </div>
              <div class="time">提交时间：{{ consult.time }}</div>
            </van-col>
            <van-col span="6">
              <div class="state">
                <van-tag :color="COLOR_M" plain v-if="consult.state === 'WAT'"
                  >待处理</van-tag
                >
                <van-tag :color="COLOR_M" plain v-if="consult.state === 'CPT'"
                  >已完成</van-tag
                >
              </div>
              <div class="btn">
                <van-tag :color="COLOR_M" @click="remind()"
                  ><van-icon name="bulb-o" /> 催单</van-tag
                >
              </div>
            </van-col>
          </van-row>
          <van-row class="no-record" v-if="consultList.length <= 0">
            <van-col span="24">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image
            ></van-col>
          </van-row>
        </van-tab>
        <van-tab title="已完成" name="settle"> </van-tab>
      </van-tabs>
    </div>
    <Loading :show="loadingShow" />
    <Tabbar active="own" />
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
