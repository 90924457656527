import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import Navbar from '../../common/Navbar.vue'
import { Tag, Icon, Tabs, Tab, Image, Cell } from 'vant'
export default {
    components: {
        Loading: Loading,
        Share: Share,
        Navbar: Navbar,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Image.name]: Image,
        [Cell.name]: Cell
    },
    data () {
        return {
            loadingShow: false,
            consultList: [],
            amount: 0
        }
    },
    mounted () {
        var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
        if (token !== '' && token !== null && token !== undefined) {
            this.retrieveConsultList()
        } else {
            this.$router.push({ path: '/mde/bind' })
        }
        this.$refs.share.default('main')
    },
    methods: {
        async retrieveConsultList () {
            this.loadingShow = true
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user, current: 1, size: 100 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/consult/retrieveUserConsultPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.consultList = res.data
            }
            this.loadingShow = false
        },
        remind () {
            this.$dialog.alert({
                title: '提示',
                message: '已进行催单,请耐心等待'
            })
        }
    }
}
